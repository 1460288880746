import feathers from '@feathersjs/feathers'
import socketio from '@feathersjs/socketio-client'
import io from 'socket.io-client'
import auth from '@feathersjs/authentication-client'

const socket = io(top.location.href);
// Set up Feathers client side
window.api = feathers();
// Register socket.io
api.configure(socketio(socket));
// Set up authentication with a store to cache your auth token
api.configure(auth({ storage: window.localStorage }));